*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// .hide {
//   display: none;
//   // visibility: hidden;
// }

// .show {
//   display: inherit;
//   // visibility: visible;
// }

html {
  scroll-behavior: smooth;
}

.fadeIn,
.fadeInAppear,
.fadeInTitle {
  opacity: 0;
  visibility: hidden;
}

img {
  width: 100%;
  display: block;
}

section:first-of-type {
  // margin-top: 6em;
}

section {
  margin-top: 2em;
}

.container {
  max-width: var(--container-width);
  width: 90%;
  margin: 0 auto;
}

.iframe-container {
  overflow: hidden;
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 0.5em;
  // box-shadow: var(--boxshadow);
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.margin-bottom-one {
  margin-bottom: 1em;
}

.margin-bottom-two {
  margin-bottom: 2em;
}
