body {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.2em;
}

// h1 {
//     font-size: 2rem;
// }

p {
  line-height: 1.5;
  margin-bottom: 1em;
}

h2,
h1 {
  line-height: 1.2;
  font-weight: 300;
  color: var(--clr-primary);
  margin-bottom: 1em;
  font-size: 2em;
}

.stage-title {
  font-size: 1.5em;
}

.staging a {
  color: var(--clr-primary);
  transition: all 0.2s ease-out;
}

.staging a:hover {
  filter: brightness(1.4);
}
