//Landing

@media (min-width: 706px) {
  .landing-title {
    top: 10%;
    left: 10%;
    font-weight: 300;
    font-size: 3em;
  }

  .landing-subtitle-container {
    right: 20%;
    top: 60%;
    font-size: 1.5em;
  }

  .section-image,
  .media-photos,
  .project-photos {
    width: 50%;
    margin-inline: auto;
  }
}

@media (min-width: 825px) {
  .landing-subtitle-container {
    top: 70%;
  }
}
@media (min-width: 880px) {
  .landing-title {
    top: 15%;
    left: 50%;

    font-size: 3.5em;
  }

  .landing-subtitle-container {
    // right: 20%;
    top: 75%;
    // font-size: 1.5em;
  }
}

@media (min-width: 1220px) {
  .landing-title {
    top: 20%;
    left: 50%;

    font-size: 4em;
  }

  .landing-subtitle-container {
    right: 10%;
    top: 75%;
    // font-size: 1.5em;
  }
}

//Rest of site
@media (max-width: 880px) {
  .navcontainer {
    display: none;
  }
}

@media (min-width: 880px) {
  h1 {
    margin-top: 3em;
  }
  .hamburger-container {
    display: none;
  }

  .navcontainer {
    position: relative;
  }

  .navdesktop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;

    height: fit-content;

    box-shadow: var(--boxshadow);
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    z-index: 10;
    background: rgba(255, 255, 255, 0.85);
    // transition: transform 0.7s ease-out;

    ul {
      display: flex;
      justify-content: space-between;
      gap: 1.5em;
      max-width: var(--container-width);
      margin: 0 auto;
    }

    li a {
      font-size: 1.5rem;
    }
  }

  // nav {
  //   transform: translate(0);
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   height: 100px;

  //   ul {
  //     flex-direction: row;
  //     gap: 2em;
  //   }
  // }
}
