.landing {
  position: relative;
  margin-bottom: 0;
  height: 100vh;
}

.landing img {
  height: 100vh;
  object-fit: cover;
  object-position: 45% 20%;
}

.landing-title,
.landing-subtitle-container {
  position: absolute;
  color: white;
}

.landing-title {
  visibility: hidden;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.landing-subtitle {
  margin-bottom: 0;
  visibility: hidden;
}

.landing-title {
  top: 10%;
  left: 10%;
  font-weight: 300;
  font-size: 2.2em;
}

.landing-subtitle-container {
  right: 10%;
  top: 50%;
}

.fa-angle-down {
  position: absolute;
  color: white;
  left: 50%;
  bottom: 2em;
  transform: translateX(-50%);
  font-size: 50px;
  font-weight: 300;
  opacity: 70%;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.quote {
  margin-top: 1em;
  margin-bottom: 0;
  line-height: 1.6;
  font-style: italic;
  letter-spacing: 0.5;
}

.concert-poster {
  background-color: rgb(248, 250, 248);
  padding: 1em;
  margin-left: -1em;
  margin-right: -1em;
  margin-bottom: 1em;
  //   box-shadow: var(--boxshadow);
}

.concert-poster-page {
  background-color: rgb(248, 250, 248);
  padding: 1em;
  margin-left: 0em;
  margin-right: 0em;
  margin-bottom: 1em;
  //   box-shadow: var(--boxshadow);
}

.concert-date {
  margin-bottom: 0;
  color: var(--clr-primary);
  font-weight: bold;
}

.concert-musicians {
  white-space: pre;
}

.concert-hall {
  font-size: 1.5em;
  margin-bottom: 0;
  line-height: 1;
}
.section-image {
  position: relative;
}

.photographer {
  position: absolute;
  bottom: 0em;
  left: 0.5em;
  color: white;
  text-shadow: 1px 1px 1px #000;
}

.section-image {
  margin-bottom: 1em;
}
